import React from 'react';

const IndexPage = () => {
  if (typeof window !== 'undefined') {
    console.log('redirect');
    window.location.href =
      'https://trial.subtlemedical.com/pet/';
  }
  return null;
};

export default IndexPage;
